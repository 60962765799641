import React, { createRef, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import Button from "components/Button";
import classNames from "lib/classNames";
import useSearch from "hook/useSearch";

export const SEARCH_TYPES = {
  "node--page": {
    title: "Rubrique",
  },
};

export default function SearchBar({ show, close, autoFocus = false }) {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { data, callFetch, cancelFetch } = useSearch();
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const searchRef = createRef();

  const handleFocus = () => {
    if (autoFocus && searchRef.current) {
      searchRef.current.focus();
    }
  };

  // Fetch search results
  useEffect(() => {
    callFetch(query);

    return () => {
      cancelFetch();
    };
  }, [query]);

  // Select result
  useEffect(() => {
    if (selected !== null) {
      router.push(selected);
      close();
    }
  }, [selected]);

  return (
    <Transition
      show={show}
      className="relative z-20"
      enter="transition-all duration-500"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-96"
      leave="transition-all duration-500"
      leaveFrom="opacity-100 max-h-96"
      leaveTo="opacity-0 max-h-0"
      afterEnter={handleFocus}
    >
      <Combobox value={null} onChange={setSelected}>
        {({ open }) => (
          <>
            <div className="relative bg-secondary-light border-b z-20">
              <div className="container mx-auto px-4">
                <div className="relative overflow-hidden z-20">
                  <span
                    className="absolute right-0 top-1/2 -translate-y-1/2"
                    aria-hidden="true"
                  >
                    <Button size="small">{t("search.submit")}</Button>
                  </span>
                  <Combobox.Input
                    ref={searchRef}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={t("search.placeholder")}
                    className="relative pl-0 pr-20 py-8 w-full appearance-none bg-transparent shadow-spread focus:outline-none font-important placeholder:text-default"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <Transition
              show={open && query !== ""}
              className="absolute inset-x-0 bg-secondary-light shadow-spread border-b z-10"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              static
            >
              <div className="container mx-auto px-4">
                <Combobox.Options className="relative py-4">
                  {data && data.length > 0 ? (
                    <>
                      {data.map((result) => {
                        return (
                          <Combobox.Option
                            key={result.id}
                            value={result.path.alias}
                            className={({ active }) =>
                              classNames(
                                active && "text-primary",
                                "flex items-center gap-1 py-4 cursor-pointer font-important transition-colors"
                              )
                            }
                          >
                            <span className="flex-1">{result.title}</span>
                            <span className="text-xs uppercase font-important font-medium tracking-wider text-primary">
                              {SEARCH_TYPES[result.type].title}
                            </span>
                          </Combobox.Option>
                        );
                      })}
                    </>
                  ) : (
                    <p className="py-4">{t("utils.empty")}</p>
                  )}
                </Combobox.Options>
              </div>
            </Transition>
          </>
        )}
      </Combobox>
    </Transition>
  );
}
