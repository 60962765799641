import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import i18nConfig from "i18n.json";

const useSearch = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  const { lang } = useTranslation();
  const { defaultLocale } = i18nConfig;

  const callFetch = (query) => {
    setLoading(true);

    fetch("/api/search/filter", {
      method: "POST",
      body: JSON.stringify({
        params: {
          filter: {
            fulltext: query,
            langcode: lang || defaultLocale,
          },
          page: {
            limit: 10,
          },
        },
        locale: lang,
        defaultLocale,
      }),
      signal: signal,
    })
      .then((response) => {
        return response.json();
      })
      .then(
        (result) => {
          setLoading(false);
          setData(result);
        },
        (error) => {
          if (error instanceof TypeError) {
            setError(error.toString());
          } else if (error instanceof Response) {
            setError({ message: error.statusText, code: error.status });
          }

          setLoading(false);
        }
      );
  };

  const cancelFetch = () => {
    controller.abort();
  };

  return { data, loading, error, callFetch, cancelFetch };
};

export default useSearch;
